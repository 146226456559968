/* @tailwind base; */
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  font-family: -apple-system, 'Poppins', BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
