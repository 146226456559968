@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

.safe-area-padding-bottom {
  padding-bottom: calc(var(--safe-area-inset-bottom) - 12px);
}

.bg-gradient {
  transition: 0.5s;
  background-image: linear-gradient(
    48deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(85, 159, 218, 1) 0%,
    rgba(0, 87, 185, 1) 99%
  ) !important;
  border: transparent !important;
}

.ant-input-end input {
  text-align: end !important;
}

.ant-btn-bids {
  background-color: #089a81 !important;
  border-color: #089a81 !important;
  color: #fff !important;
}

.ant-btn-bids:hover {
  color: #fff !important;
}

.ant-btn-bids-outline {
  background-color: transparent !important;
  border-color: #089a81 !important;
  color: #089a81 !important;
}

.ant-btn-outline:hover {
  color: #089a81 !important;
}

.ant-btn-asks {
  background-color: #f33645 !important;
  border-color: #f33645 !important;
  color: #fff !important;
}

.ant-btn-asks-outline {
  border-color: #f33645 !important;
  color: #f33645 !important;
}
